<template>
  <div class="p-d-flex p-flex-column">
    <div class="p-mb-4">
      <Textarea
        v-model="modelAnalysisInfo"
        :autoResize="true"
        rows="2"
        style="width:100%"
      />
    </div>
    <div class="p-mb-4">
      <td width="100%">
        <span class="p-float-label">
          <InputText
            id="dataLink"
            type="text"
            v-model="modelDataURL"
            style="width:100%"
          />
          <label for="dataLink">
            URL источника данных
          </label>
        </span>
      </td>
      <td>
        <Button
          class="p-button-text"
          icon="pi pi-external-link"
          iconPos="right"
          :disabled="modelDataURL == null"
          @click="openURL(modelDataURL)"
        />
      </td>
    </div>
    <div class="p-d-flex p-jc-start">
      <Button
        label="Отменить"
        icon="pi pi-times"
        @click="cancel"
        class="p-button-text"
      />
      <Button label="Сохранить" icon="pi pi-check" @click="save" autofocus />
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'

export default {
  name: 'FileInfo',
  components: {
    InputText,
    Button,
    Textarea,
  },
  emits: ['update:dataURL', 'update:analysisInfo', 'close'],
  props: {
    dataURL: {
      type: String,
      default: null,
    },
    analysisInfo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modelDataURL: this.dataURL,
      modelAnalysisInfo: this.analysisInfo,
    }
  },
  methods: {
    // opens URL
    openURL(url) {
      window.open(url, '_blank')
    },
    // emits save event
    save() {
      this.$emit('update:dataURL', this.modelDataURL)
      this.$emit('update:analysisInfo', this.modelAnalysisInfo)
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped></style>
