<template>
  <card>
    <template #title>
      <h3>Статистика правил по периодам</h3>
      <h5>
        Всего правил: {{ totalRulesNumber }} из них выбрано:
        {{ filteredRulesNumber }}
      </h5>
    </template>
    <template #content>
      <div class="p-d-flex p-flex-column">
        <div class="p-mb-6" style="width:60%">
          <Chart
            type="line"
            :data="periodsLineChart"
            :options="optionsLineChart"
          />
        </div>
        <div class="p-mb-2">
          <DataTable
            :value="periodsData"
            :rowClass="abnormalPeriod"
            v-model:expandedRows="expandedRows"
            responsiveLayout="scroll"
            class="p-datatable-sm"
          >
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column field="year" header="Год" sortable></Column>
            <Column field="true_count" header="Подтвердилось" sortable></Column>
            <Column field="false_count" header="Нарушилось" sortable></Column>
            <Column field="break_count" header="Не сложилось" sortable></Column>
            <Column
              field="true_percent"
              header="% подтверждений"
              sortable
            ></Column>
            <Column
              field="false_percent"
              header="% нарушений"
              sortable
            ></Column>
            <Column
              field="break_percent"
              header="% не сложившихся"
              sortable
            ></Column>
            <template #expansion="slotProps">
              <div class="months-subtable">
                <h3>По месяцам {{ slotProps.data.year }}</h3>
                <DataTable
                  :value="Object.values(slotProps.data.months)"
                  responsiveLayout="scroll"
                  class="p-datatable-sm"
                >
                  <Column field="month" header="Месяц" sortable></Column>
                  <Column
                    field="true_count"
                    header="Подтвердилось"
                    sortable
                  ></Column>
                  <Column
                    field="false_count"
                    header="Нарушилось"
                    sortable
                  ></Column>
                  <Column
                    field="break_count"
                    header="Не сложилось"
                    sortable
                  ></Column>
                  <Column
                    field="true_percent"
                    header="% подтверждений"
                    sortable
                  ></Column>
                  <Column
                    field="false_percent"
                    header="% нарушений"
                    sortable
                  ></Column>
                  <Column
                    field="break_percent"
                    header="% не сложившихся"
                    sortable
                  ></Column>
                </DataTable>
              </div>
            </template>
          </DataTable>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Card from 'primevue/card'
import Chart from 'primevue/chart'

import { sampleStandardDeviation, mean } from 'simple-statistics'

export default {
  props: {
    periodsData: {
      type: Array,
      default: null,
      required: true,
    },
    periodsLineChart: {
      type: Object,
      default: null,
      required: true,
    },
    totalRulesNumber: {
      type: Number,
      default: 0,
      required: true,
    },
    filteredRulesNumber: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  components: {
    DataTable,
    Column,
    Card,
    Chart,
  },
  data() {
    return {
      expandedRows: [],
      optionsLineChart: {
        plugins: {
          legend: {
            labels: {
              color: '#495057',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
        },
      },
    }
  },
  methods: {
    abnormalPeriod(data) {
      let columnTruePercent = []
      let columnFalsePercent = []
      let columnBreakPercent = []

      for (let row of this.periodsData) {
        if (row.year != data.year) {
          columnTruePercent.push(row.true_percent)
          columnFalsePercent.push(row.false_percent)
          columnBreakPercent.push(row.break_percent)
        }
      }

      // console.log('Length = ', columnTruePercent.length)

      let sdTruePercent = sampleStandardDeviation(columnTruePercent)
      let sdFalsePercent = sampleStandardDeviation(columnFalsePercent)
      let sdBreakPercent = sampleStandardDeviation(columnBreakPercent)

      let meanTruePercent = mean(columnTruePercent)
      let meanFalsePercent = mean(columnFalsePercent)
      let meanBreakPercent = mean(columnBreakPercent)

      let abnormalTrue =
        -(data.true_percent - meanTruePercent) > 1 * sdTruePercent
      let abnormalFalse =
        data.false_percent - meanFalsePercent > 1 * sdFalsePercent
      let abnormalBreak =
        data.break_percent - meanBreakPercent > 1 * sdBreakPercent

      // console.log(meanFalsePercent, sdFalsePercent)

      return abnormalTrue || abnormalFalse || abnormalBreak
        ? 'abnormal-period'
        : null
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.abnormal-period) {
  background-color: rgba(243, 120, 196, 0.15) !important;
}
.months-subtable {
  padding: 1rem;
}
</style>
