<template>
  <div style="height: calc(100vh - 270px)">
    <DataTable
      :value="rulesTableData"
      rowGroupMode="subheader"
      groupRowsBy="variable.key"
      sortMode="single"
      sortField="variable.name"
      :sortOrder="1"
      scrollable
      scrollHeight="flex"
      class="p-datatable-sm"
      @rowContextmenu="onRowContextMenu"
      @row-click="onRowClick"
      v-model:contextMenuSelection="selectedRow"
    >
      <Column
        field="name"
        header="Моделируемая переменная/Фактор"
        style="flex: 0 0 40rem"
      ></Column>
      <Column field="lag" header="Лаг"></Column>
      <Column field="change" header="Изменения"></Column>
      <Column field="fisher" header="Фишер"></Column>
      <Column field="influence" header="Влияние"></Column>
      <template #groupheader="slotProps">
        <div>
          <span>{{ slotProps.data.variable.name }}</span>
        </div>
      </template>
      <template #groupfooter="slotProps">
        <td style="min-width: 80%">
          <div style="text-align: center; width: 100%">
            <span>Вероятность: {{ slotProps.data.variable.probability }}</span>
            <span> Объектов: {{ slotProps.data.variable.objects }}</span>
          </div>
        </td>
      </template>
    </DataTable>
    <ContextMenu :model="menuModel" ref="cm" />
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ContextMenu from 'primevue/contextmenu'

export default {
  emits: ['showRuleDetails', 'showRuleStructure'],
  props: {
    rulesTableData: {
      type: Array,
      default: null,
      required: true,
    },
  },
  components: {
    DataTable,
    Column,
    ContextMenu,
  },
  methods: {
    onRowContextMenu(event) {
      this.$refs.cm.show(event.originalEvent)
    },
    onRowDetails(row) {
      this.$emit('showRuleDetails', row.variable.key)
    },
    onRowClick(event) {
      this.$emit('showRuleDetails', event.data.variable.key)
    },
    onRowStructure(row) {
      this.$emit('showRuleStructure', row.variable.key)
    },
  },
  data() {
    return {
      menuModel: [
        {
          label: 'Подробнее',
          icon: 'pi pi-fw pi-search',
          command: () => this.onRowDetails(this.selectedRow),
        },
        {
          label: 'Структура',
          icon: 'bi bi-diagram-3',
          command: () => this.onRowStructure(this.selectedRow),
        },
      ],
      selectedRow: null,
    }
  },
}
</script>

<style lang="scss" scoped>
.p-rowgroup-footer td {
  font-weight: 700;
}

.p-rowgroup-header td {
  background-color: red;
  // width: 100%;
  padding: 0;
  border: 0 none;
}

::v-deep(.p-rowgroup-header) {
  // background-color: var(--purple-200);
  span {
    font-weight: 700;
    // background-color: var(--purple-200);
    // color: var(--primary-color-text);
  }

  .p-row-toggler {
    vertical-align: middle;
    margin-right: 0.25rem;
  }
}
</style>
