<template>
  <card>
    <template #title>
      <h3>Частота факторов в подтвердившихся правилах по годам</h3>
      <h5>Количество факторов: {{ factorsData.length }}</h5>
    </template>
    <template #content>
      <div class="p-d-flex p-flex-column">
        <div class="p-mb-6" style="width:50%;">
          <h3>ТОП-5</h3>
          <Chart
            type="bar"
            :data="factorsBarChart"
            :options="optionsBarChart"
          />
        </div>
        <div class="p-mb-2">
          <DataTable
            :value="factorsData"
            v-model:expandedRows="expandedRows"
            responsiveLayout="scroll"
            class="p-datatable-sm"
          >
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column field="factor" header="Фактор" sortable></Column>
            <Column
              v-for="col of columns"
              :field="col.toString()"
              :header="col.toString()"
              :key="col.toString()"
              sortable
            ></Column>
            <Column field="total" header="Всего" sortable></Column>

            <template #expansion="slotProps">
              <div class="details-subtable">
                <h3>С учетом лагов {{ slotProps.data.factor }}</h3>
                <DataTable
                  :value="Object.values(slotProps.data.lags)"
                  responsiveLayout="scroll"
                  class="p-datatable-sm"
                >
                  <Column field="lag" header="Лаг" sortable></Column>
                  <Column
                    v-for="col of columns"
                    :field="col.toString()"
                    :header="col.toString()"
                    :key="col.toString()"
                    sortable
                  ></Column>
                  <Column field="total" header="Всего" sortable></Column>
                </DataTable>
              </div>
            </template>
          </DataTable>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Card from 'primevue/card'
import Chart from 'primevue/chart'

export default {
  props: {
    factorsData: {
      type: Array,
      default: null,
      required: true,
    },
    factorsBarChart: {
      type: Object,
      default: null,
      required: true,
    },
  },
  components: {
    DataTable,
    Column,
    Card,
    Chart,
  },
  created() {
    this.columns = [...Array(11).keys()].map((i) => i + 2011)
  },
  data() {
    return {
      expandedRows: [],
      columns: null,
      optionsBarChart: {
        plugins: {
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            labels: {
              color: '#495057',
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
        },
      },
    }
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.details-subtable {
  padding: 1rem;
}
</style>
