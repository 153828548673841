<template>
  <!-- Окно для показа структуры правил (факторов) -->
  <Dialog
    v-model:visible="displayTree"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '80vw' }"
    :maximizable="true"
  >
    <div class="card">
      <OrganizationChart
        :value="ruleTree"
        :collapsible="true"
        selectionMode="multiple"
        v-model:selectionKeys="selectionKeys"
        @node-select="selectTreeNode"
      >
        <template #default="slotProps">
          <div
            class="rule-header ui-corner-top"
            :class="slotProps.node.children ? null : 'rule-header-alt'"
          >
            <table height="min-content" box-sizing="border-box">
              <tr>
                <td>
                  <badge
                    v-if="slotProps.node.data.rating"
                    :value="slotProps.node.data.rating"
                    :severity="getRatingSeverity(slotProps.node.data.rating)"
                  ></badge>
                </td>
                <td width="100%">
                  <span v-if="slotProps.node.children">Фактор</span>
                  <span v-else>Моделируемая переменная</span>
                </td>
                <td width="min-content">
                  <Button
                    class="p-button-text p-button-sm"
                    @click.prevent.stop="treeNodeClick($event, slotProps)"
                  >
                    <span
                      class="pi pi-bars"
                      style="font-size:x-small;text-align:right;color:#ffffff;"
                    ></span>
                  </Button>
                  <TieredMenu
                    ref="treeMenu"
                    :model="treeMenuData"
                    :popup="true"
                  >
                    <template #item="{item}">
                      <div
                        v-if="item.label === 'Рейтинг'"
                        class="p-menuitem-link"
                        style="font-size: x-small"
                      >
                        <Rating
                          v-model="activeProps.node.data.rating"
                          v-if="Object.keys(selectionKeys).length"
                          v-badge="Object.keys(selectionKeys).length"
                          @change="changeRatingTreeNodes"
                        />
                        <Rating
                          v-model="activeProps.node.data.rating"
                          v-else
                          @change="changeRatingTreeNodes"
                        />
                      </div>
                      <a
                        v-else
                        :href="item.url"
                        :class="getLinkClass(item)"
                        :target="item.target"
                        :aria-haspopup="item.items != null"
                        @click="item.command"
                        role="menuitem"
                        :tabindex="item.disabled ? null : '0'"
                        v-ripple
                      >
                        <span :class="['p-menuitem-icon', item.icon]"></span>
                        <span
                          class="p-menuitem-text"
                          v-if="Object.keys(selectionKeys).length"
                          v-badge="Object.keys(selectionKeys).length"
                        >
                          {{ item.label }}
                        </span>
                        <span class="p-menuitem-text" v-else>
                          {{ item.label }}
                        </span>
                        <span
                          class="p-submenu-icon pi pi-angle-right"
                          v-if="item.items"
                        ></span>
                      </a>
                    </template>
                  </TieredMenu>
                </td>
              </tr>
            </table>
          </div>

          <div class="rule-content">
            <span>{{ slotProps.node.data.name }}</span>
            <div style="text-align:left" v-if="slotProps.node.children == null">
              <hr />
              Вер:{{ Number(slotProps.node.data.probability).toFixed(2) }}<br />
              Фиш:{{ getShortFisher(slotProps.node) }}<br />
              Объ:{{ slotProps.node.data.objects }}
            </div>
            <div v-else>
              <hr />
              Лаг:{{ slotProps.node.data.lag }}, Изм:{{
                slotProps.node.data.change
              }}
            </div>
          </div>
        </template>
      </OrganizationChart>
    </div>
  </Dialog>

  <!-- Окно для заметок -->
  <Dialog
    header="Заметки"
    v-model:visible="displayNote"
    :style="{ width: '25vw' }"
  >
    <div class="p-d-flex p-jc-center p-flex-column">
      <div class="p-mb-2">
        <div class="p-d-flex p-jc-between">
          <div class="p-mr-2">
            <Rating v-model="rating" />
          </div>
          <div class="p-mr-2">
            <div class="p-field-checkbox">
              <Checkbox id="binary" v-model="checkbox" :binary="true" />
              <label for="binary">Во всех выделенных&nbsp;</label>
              <badge
                :value="Object.keys(selectionKeys).length"
                v-if="checkbox"
              ></badge>
            </div>
          </div>
        </div>
      </div>
      <div class="p-mb-2">
        <Textarea
          v-model="valueNote"
          placeholder="Добавьте заметку"
          rows="5"
          style="width: 100%"
        />
      </div>
    </div>
    <template #footer>
      <div class="p-d-flex p-jc-center">
        <Button
          label="Отменить"
          icon="pi pi-times"
          @click="displayNote = false"
          class="p-button-text"
        />
        <Button
          label="Сохранить"
          icon="pi pi-check"
          @click="saveNote"
          autofocus
        />
      </div>
    </template>
  </Dialog>

  <!--  Окно для показа данных по правилу   -->
  <Dialog
    v-model:visible="displayDetails"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '55vw' }"
  >
    <rule-details
      v-model:ruleStructure="ruleStructure"
      v-model:monthData="monthData"
      v-model:yearsData="yearsData"
      v-model:ruleData="ruleData"
    />
  </Dialog>

  <!-- Окно для ввода информации о файле правил -->
  <Dialog
    header="Информация"
    v-model:visible="displayInfo"
    :style="{ width: '30vw' }"
  >
    <file-info
      v-model:dataURL="dataURL"
      v-model:analysisInfo="analysisInfo"
      @close="displayInfo = false"
    />
  </Dialog>

  <!-- Окно для вывода информации о периодах -->
  <Dialog
    v-model:visible="displayPeriods"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '80vw' }"
    :maximizable="true"
  >
    <periods
      v-model:periodsData="periodsData"
      v-model:periodsLineChart="periodsLineChart"
      :totalRulesNumber="nodes.length"
      :filteredRulesNumber="filteredValue ? filteredValue.length : nodes.length"
    />
  </Dialog>

  <!-- Окно для вывода информации о факторах -->
  <Dialog
    v-model:visible="displayFactors"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '90vw' }"
    :maximizable="true"
  >
    <factors
      v-model:factorsData="factorsData"
      v-model:factorsBarChart="factorsBarChart"
    />
  </Dialog>

  <!-- Main Window -->
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card" v-if="displayRulesAsTable">
        <rules-table
          v-model:rulesTableData="rulesTableData"
          @showRuleDetails="showRuleDetails"
          @showRuleStructure="showRuleStructure"
        />
      </div>
      <div class="card" v-else style="height: calc(100vh - 240px)">
        <!-- <TreeTable
          :value="nodes"
          :filters="filters1"
          filterMode="lenient"
          :resizableColumns="true"
          columnResizeMode="fit"
          showGridlines
          sortMode="single"
          :expandedKeys="expandedKeys"
          :autoLayout="true"
          :indentation="2"
          @filter="onFiltered"
        > -->
        <TreeTable
          :value="nodes"
          :filters="filters1"
          filterMode="lenient"
          :resizableColumns="true"
          columnResizeMode="fit"
          showGridlines
          sortMode="single"
          :expandedKeys="expandedKeys"
          :indentation="2"
          @filter="onFiltered"
          scrollable
          scrollHeight="flex"
        >
          <Column
            field="name"
            header="Правила/Факторы"
            :expander="true"
            key="name"
            :sortable="true"
            style="flex: 1 0 35rem;"
          >
            <template #filter>
              <InputText
                type="text"
                v-model="filters1['search']"
                class="p-column-filter"
                placeholder="Фильтр"
              />
            </template>
          </Column>
          <Column
            field="search"
            header="Search"
            key="search"
            hidden="true"
            filterMatchMode="keywords"
          >
          </Column>
          <Column
            v-for="col of selectedColumns"
            :field="col.field"
            :header="col.header"
            :key="col.field"
            :sortable="col.sortable"
            :filterMatchMode="col.filter"
          >
            <template #filter>
              <InputText
                :style="col.style ? col.style : null"
                type="text"
                v-model="filters1[col.field]"
                class="p-column-filter"
                :placeholder="col.filterText ? col.filterText : 'Фильтр'"
              />
            </template>
          </Column>
          <Column
            headerClass="p-text-center"
            bodyClass="p-text-center"
            style="flex: 0 0 5rem"
          >
            <template #header>
              <Button
                type="button"
                icon="pi pi-cog"
                class="p-button-text"
              ></Button>
            </template>
            <template #body="slotProps">
              <Button
                type="button"
                icon="pi pi-bars"
                style="margin-right: .5em"
                class="p-button-text"
                @click="details($event, slotProps)"
              ></Button>
              <span font-size="xx-small">
                <Badge
                  v-if="slotProps.node.data.rating"
                  :value="slotProps.node.data.rating"
                  :severity="getRatingSeverity(slotProps.node.data.rating)"
                ></Badge>
              </span>
              <TieredMenu
                :ref="'dtlMenu-' + slotProps.node.key"
                :model="detailsMenuData"
                :popup="true"
              >
                <template #item="{item}">
                  <div
                    v-if="item.label === 'Рейтинг'"
                    class="p-menuitem-link"
                    style="font-size: x-small"
                  >
                    <Rating v-model="activeProps.node.data.rating" />
                  </div>
                  <a
                    v-else
                    :href="item.url"
                    :class="getLinkClass(item)"
                    :target="item.target"
                    :aria-haspopup="item.items != null"
                    @click="item.command"
                    role="menuitem"
                    :tabindex="item.disabled ? null : '0'"
                    v-ripple
                  >
                    <span :class="['p-menuitem-icon', item.icon]"></span>
                    <span class="p-menuitem-text">{{ item.label }}</span>
                    <span
                      class="p-submenu-icon pi pi-angle-right"
                      v-if="item.items"
                    ></span>
                  </a>
                </template>
              </TieredMenu>
            </template>
          </Column>
        </TreeTable>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import TreeTable from 'primevue/treetable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import Dialog from 'primevue/dialog'
import TieredMenu from 'primevue/tieredmenu'
import Ripple from 'primevue/ripple'
import OrganizationChart from 'primevue/organizationchart'
import Textarea from 'primevue/textarea'
import { FilterService } from 'primevue/api'
import Rating from 'primevue/rating'
import BadgeDirective from 'primevue/badgedirective'
import Badge from 'primevue/badge'
import Checkbox from 'primevue/checkbox'

import NodeService from '@/service/NodeService'

import { fileOpen, fileSave } from 'browser-fs-access'

// My Components
import FileInfo from '@/components/FileInfo'
import Periods from '@/components/Periods.vue'
import Factors from '@/components/Factors.vue'
import RulesTable from '@/components/RulesTable.vue'
import RuleDetails from '@/components/RuleDetails.vue'
import { inject } from 'vue'

export default {
  setup() {
    const topBarMenus = inject('topBarMenus')
    const transitRules = inject('transitRules')
    const hideSpinner = inject('hideSpinner')
    const showSpinner = inject('showSpinner')

    return {
      topBarMenus,
      transitRules,
      hideSpinner,
      showSpinner,
    }
  },
  components: {
    TreeTable,
    Column,
    InputText,
    Button,
    Dialog,
    TieredMenu,
    OrganizationChart,
    Textarea,
    Rating,
    Badge,
    Checkbox,
    // My components
    FileInfo,
    Periods,
    Factors,
    RulesTable,
    RuleDetails,
  },
  directives: {
    tooltip: Tooltip,
    ripple: Ripple,
    badge: BadgeDirective,
  },
  updated() {
    console.log('Updated', this.isLoading)
    // this.$nextTick(function() {
    //   // Код, который будет запущен только после
    //   // переотрисовки всех представлений
    //   console.log('Updated', this.isLoading)
    // })
  },

  /**
   * @type {boolean} displayTree
   * @type {Array} filteredValue
   * @type {Array} nodes
   */
  data() {
    return {
      // Индикатор загрузки данных
      isLoading: false,
      // Отфильтрованные правила
      filteredValue: null,
      // URL источника данных (например GoogleDocs)
      dataURL: null,
      // Analysis Info (File Info, Data Info)
      analysisInfo: null,
      // Checkbox
      checkbox: false,
      // Рейтинг
      rating: null,
      // Статусы видимости окон
      displayDetails: false,
      displayTree: false,
      displayNote: false,
      displayInfo: false,
      displayPeriods: false,
      displayFactors: false,
      // Фильтры
      filters1: {},
      filters2: {},
      // Значение для редактирования заметки
      valueNote: null,
      selectionKeys: {},
      nodes: null,
      expandedKeys: {},
      activeProps: null,
      monthData: {
        labels: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        datasets: [
          {
            label: 'Правило подтвердилось',
            backgroundColor: '#46911a',
            data: null,
          },
          {
            label: 'Правило нарушилось',
            backgroundColor: '#FFA726',
            data: null,
          },
        ],
      },
      yearsData: {
        labels: [],
        datasets: [
          {
            label: 'Правило подтвердилось',
            backgroundColor: '#46911a',
            data: null,
          },
          {
            label: 'Правило нарушилось',
            backgroundColor: '#FFA726',
            data: null,
          },
        ],
      },
      ruleData: null,
      // Rule structure
      ruleStructure: null,
      // Tree of rules
      ruleTree: null,
      // Details menu for rules
      detailsMenuData: [
        {
          label: 'Подробнее',
          icon: 'pi pi-fw pi-chart-bar',
          command: () => {
            this.prepareRuleDetails(this.activeProps.node)
            this.displayDetails = true
          },
          visible: () => this.activeProps.node.data.execs != '',
        },
        {
          label: 'Структура',
          icon: 'pi pi-fw pi-sitemap',
          command: () => {
            this.prepareRuleTree(this.activeProps.node)
            this.displayTree = true
          },
        },
        {
          label: 'Заметки',
          icon: 'pi pi-fw pi-pencil',
          command: () => {
            if (this.activeProps.node.data.note == null) {
              this.valueNote = ''
            } else {
              this.valueNote = this.activeProps.node.data.note.valueOf()
            }
            this.checkbox = false
            this.rating = this.activeProps.node.data.rating
            this.displayNote = true
          },
        },
        {
          label: 'Рейтинг',
        },
      ],
      // Menu for Tree of rules
      treeMenuData: [
        {
          label: 'Подробнее',
          icon: 'pi pi-fw pi-chart-bar',
          command: () => {
            this.prepareRuleDetails(this.activeProps.node)
            this.displayDetails = true
          },
          visible: () => this.activeProps.node.data.execs != '',
        },
        {
          label: 'Заметки',
          icon: 'pi pi-fw pi-pencil',
          command: () => {
            if (this.activeProps.node.data.note == null) {
              this.valueNote = ''
            } else {
              this.valueNote = this.activeProps.node.data.note.valueOf()
            }
            this.checkbox = false
            this.rating = this.activeProps.node.data.rating
            this.displayNote = true
          },
        },
        {
          label: 'Рейтинг',
        },
        {
          label: 'Очистить выделение',
          icon: 'pi pi-fw pi-filter-slash',
          command: () => {
            this.selectionKeys = {}
          },
        },
      ],
      // Main Menu
      mainMenu: [
        {
          label: 'Файл',
          icon: 'pi pi-fw pi-file',
          command: (event) => {
            event.item.items[1].disabled = this.nodes == null
            event.item.items[3].disabled = this.nodes == null
          },
          items: [
            {
              label: 'Открыть',
              icon: 'pi pi-fw pi-folder-open',
              command: () => {
                this.openFile()
              },
            },
            {
              label: 'Сохранить',
              icon: 'pi pi-fw pi-save',
              command: () => {
                this.saveFile()
              },
            },
            {
              separator: true,
            },
            {
              label: 'Экспорт CSV',
              icon: 'pi pi-fw pi-external-link',
              command: () => {
                this.exportCsvFile()
              },
            },
          ],
        },
        {
          label: 'Вид',
          icon: 'bi bi-layout-text-window',
          command: (event) => {
            for (let item of event.item.items) {
              item.disabled = this.nodes == null
            }
          },
          items: [
            {
              label: 'Табличный вид',
              icon: 'bi bi-table',
              command: (event) => {
                this.startLoading()
                let tableView = !this.displayRulesAsTable
                if (tableView) {
                  this.prepareRulesTable()
                  event.item.label = 'Базовый вид'
                  event.item.icon = 'bi bi-layout-text-window'
                } else {
                  event.item.label = 'Табличный вид'
                  event.item.icon = 'bi bi-table'
                }
                let f = () => {
                  this.displayRulesAsTable = tableView
                }
                let stop = this.stopLoading
                setTimeout(function() {
                  f()
                  stop()
                }, 300)
              },
            },
            {
              label: 'Раскрыть всё',
              icon: 'pi pi-fw pi-search-plus',
              command: () => {
                this.expandAll()
              },
              visible: () => !this.displayRulesAsTable,
            },
            {
              label: 'Схлопнуть всё',
              icon: 'pi pi-fw pi-search-minus',
              command: () => {
                this.collapseAll()
              },
              visible: () => !this.displayRulesAsTable,
            },
          ],
        },
        {
          label: 'Анализ',
          icon: 'pi pi-fw pi-desktop',
          command: (event) => {
            event.item.items[0].disabled = this.nodes == null
            event.item.items[1].disabled = this.nodes == null
          },
          items: [
            {
              label: 'Периоды',
              icon: 'bi bi-calendar2-week',
              command: () => {
                this.preparePeriods()
                this.displayPeriods = true
              },
            },
            {
              label: 'Факторы',
              icon: 'bi bi-rulers',
              command: () => {
                this.prepareFactors()
                this.displayFactors = true
              },
            },
          ],
        },
        {
          label: 'Инфо',
          icon: 'bi bi-info-circle-fill',
          command: () => {
            if (this.nodes) {
              this.displayInfo = true
            }
          },
        },
      ],
      // Данные для периодов
      periodsData: null,
      periodsLineChart: null,

      // Данные по факторам
      factorsData: null,
      factorsBarChart: null,

      // Данные для отображения правил в виде таблицы
      rulesTableData: null,
    }
  },
  computed: {
    commonTransitRules() {
      return this.transitRules?.value
    },
    model: {
      get() {
        return this.topBarMenus.resultPageMenu.model
      },
      set(newValue) {
        this.topBarMenus.resultPageMenu.model = newValue
      },
    },
    displayRulesAsTable: {
      get() {
        return this.topBarMenus.resultPageMenu.displayRulesAsTable
      },
      set(newValue) {
        this.topBarMenus.resultPageMenu.displayRulesAsTable = newValue
      },
    },
    selectedColumns: {
      get() {
        return this.topBarMenus.resultPageMenu.selectedColumns
      },
      set(newValue) {
        this.topBarMenus.resultPageMenu.selectedColumns = newValue
      },
    },
    columns: {
      get() {
        return this.topBarMenus.resultPageMenu.columns
      },
      set(newValue) {
        this.topBarMenus.resultPageMenu.columns = newValue
      },
    },
    onColumnsToggle: {
      get() {
        return this.topBarMenus.resultPageMenu.onToggle
      },
      set(newValue) {
        this.topBarMenus.resultPageMenu.onToggle = newValue
      },
    },
  },
  watch: {
    commonTransitRules(newValue) {
      if (newValue && newValue.root) {
        const data = this.transitRules.value.root
        this.transitRules.value = null

        this.openDataImmediately(data)
      }
    },
  },
  nodeService: null,
  created() {
    this.nodeService = new NodeService()
    this.columns = [
      {
        field: 'lag',
        header: 'Лаг',
        filter: 'isBiggerNumber',
        filterText: 'Нижняя граница',
        sortable: true,
        style: 'width: 5em',
      },
      {
        field: 'change',
        header: 'Изменения',
        filter: 'isBiggerNumber',
        filterText: 'Нижняя граница',
        sortable: true,
        style: 'width: 5em',
      },
      {
        field: 'probability',
        header: 'Вероятность',
        filter: 'isBiggerNumber',
        filterText: 'Нижняя граница',
        sortable: false,
        style: 'width: 5em',
      },
      {
        field: 'fisher',
        header: 'Фишер',
        filter: 'isLessNumber',
        filterText: 'Верхняя граница',
        sortable: false,
        style: 'width: 5em',
      },
      {
        field: 'objects',
        header: 'Объекты',
        filter: 'isBiggerNumber',
        filterText: 'Нижняя граница',
        sortable: false,
        style: 'width: 5em',
      },
      {
        field: 'goal',
        header: 'Результат',
        filter: 'contains',
        sortable: true,
        style: 'width: 7em',
      },
      {
        field: 'note',
        header: 'Заметки',
        filter: 'keywords',
        sortable: false,
        style: 'width: 7em',
      },
      {
        field: 'rating',
        header: 'Рейтинг',
        filter: 'isBiggerNumber',
        filterText: '1-5',
        sortable: true,
        style: 'width: 3em',
      },
    ]
    // this.selectedColumns = this.columns

    FilterService.register('isBiggerNumber', (value, filter) => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true
      }

      if (value === undefined || value === null) {
        return false
      }

      return Number(value) >= Number(filter)
      // console.log('IS BIGGER')
      // return true
    })

    FilterService.register('isLessNumber', (value, filter) => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true
      }

      if (value === undefined || value === null || value === '') {
        return false
      }

      return Number(value) <= Number(filter)
    })

    FilterService.register('keywords', (value, filter) => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true
      }

      if (value === undefined || value === null || value === '') {
        return false
      }

      const keywords = filter.toLowerCase().split(' ')
      const v = value.toLowerCase()

      for (const k of keywords) {
        if (!v.includes(k)) {
          return false
        }
      }
      return true
    })
  },
  mounted() {
    this.model = this.mainMenu
    this.onColumnsToggle = this.onToggle

    if (this.commonTransitRules?.root) {
      const data = this.transitRules.value.root
      this.transitRules.value = null

      this.openDataImmediately(data)
    }

    // this.nodeService.getTreeTableNodes().then((data) => (this.nodes = data))
  },
  // renderTriggered({ key, target, type }) {
  //   console.log('Render triggered', { key, target, type })
  // },
  methods: {
    startLoading() {
      this.isLoading = true
      this.showSpinner()
    },
    stopLoading() {
      this.isLoading = false
      this.hideSpinner()
    },

    openDataImmediately(data) {
      this.nodes = data
      this.analysisInfo = this.nodes[0].data.analysisInfo
      this.dataURL = this.nodes[0].data.dataURL
    },

    // Вызывается после фильтрации правил в TreeTable
    onFiltered(event) {
      console.log('Filtered', event)
      this.filteredValue = event.filteredValue
    },
    // Добавляет к фактору его лаг и изменения
    getFullName(n) {
      let fullName = n.data.name
      if (n.data.lag) {
        fullName += ' [ лаг = ' + n.data.lag + ', изм = ' + n.data.change + ']'
      }
      return fullName
    },

    // Возвращает короткий вариант массива с критериями Фишера
    getShortFisher(n) {
      return JSON.stringify(Object.values(n.data.fisher))
    },

    // Применяет функцию ко всем узлам дерева, перечисленным в массиве ключей keys.
    // Если массив ключей не задан, то функция применяется ко всему дереву
    mapNodes(func, keys = null) {
      if (keys != null) {
        // Обход дерева по списку ключей
        for (let key of keys) {
          const indexes = key.split('-').map(Number)
          let node = null
          for (let idx of indexes) {
            if (node) {
              node = node.children[idx]
            } else {
              node = this.nodes[idx]
            }
          }
          func(node)
        }
      } else {
        // Обход всего дерева
        this.mapRules(func)
      }
    },

    // Применяет функцию к указанному массиву правил
    mapRules(func, rules = this.nodes) {
      let array = rules != null ? rules : this.nodes
      for (let node of array) {
        this.mapNode(func, node)
      }
    },

    // Применяет функцию к узлу дерева и ко всем его потомкам
    mapNode(func, node) {
      func(node)
      if (node.children) {
        for (let child of node.children) {
          this.mapNode(func, child)
        }
      }
    },

    // Возвращает массив родителей узла в дереве, начиная с вершины
    getParents(key, nodesArray = this.nodes) {
      let res = []
      if (key && nodesArray) {
        for (let node of nodesArray) {
          if (node.key != key && key.startsWith(node.key)) {
            res = this.getParents(key, node.children)
            res.unshift(node)
          }
        }
      }

      return res
    },

    // Возвращает вершину дерева по ее ключу
    getNodeByKey(key, nodesArray = this.nodes) {
      let res = null
      if (key && nodesArray) {
        for (let node of nodesArray) {
          if (node.key == key) {
            res = node
            break
          } else if (key.startsWith(node.key)) {
            res = this.getNodeByKey(key, node.children)
          }
        }
      }
      return res
    },

    // Изменяет рейтинг у отмеченных правил в дереве правил
    changeRatingTreeNodes(event) {
      this.mapNodes((n) => {
        n.data.rating = event.value
      }, Object.keys(this.selectionKeys))
    },

    // select node on RulesTree
    selectTreeNode(node) {
      this.mapNodes(
        (n) => {
          console.log('Select Trree NNoodde', n, this.selectionKeys)
        },
        [node.key]
      )
    },

    // Возвращает нужный цвет для бейджа у рейтинга
    // По цветам светофора: красный, зеленый и желтый
    getRatingSeverity(rating) {
      if (rating > 3) {
        return 'success'
      } else if (rating < 2) {
        return 'danger'
      }

      return 'warning'
    },

    // Вспомогательный метод для меню
    getLinkClass(item) {
      return ['p-menuitem-link', { 'p-disabled': item.disabled }]
    },

    // Save note to selected node
    saveNote() {
      this.activeProps.node.data.note = this.valueNote
      this.activeProps.node.data.rating = this.rating
      if (this.checkbox) {
        this.mapNodes((n) => {
          n.data.note = this.valueNote
          n.data.rating = this.rating
        }, Object.keys(this.selectionKeys))
      }
      this.displayNote = false
    },

    treeNodeClick(event, props) {
      // console.log('Context Tree Menu!!!', props)
      this.activeProps = props
      this.$refs.treeMenu.toggle(event)
      // event.preventDefault()
    },

    details(event, props) {
      // console.log('details!', props, this.$refs)
      // console.log('Rule Details', props.node.data.name)
      this.activeProps = props
      let menu = 'dtlMenu-' + props.node.key
      this.$refs[menu].toggle(event)
    },

    // Подготавливает дерево для правила
    prepareRuleTree(node) {
      // Fill rule structure
      let roots = this.filteredValue ? this.filteredValue : this.nodes
      const indexes = node.key.split('-')
      for (let root of roots) {
        if (root.key == indexes[0]) {
          this.ruleTree = root
          break
        }
      }
      this.addNodeStyle(this.ruleTree, 'rules')
    },

    addNodeStyle(node, style) {
      node.styleClass = style
      if (node.children != null) {
        for (let child of node.children) {
          this.addNodeStyle(child, style)
        }
      }
    },

    // Показывает детальную статистику по ключу правила
    showRuleDetails(key) {
      let node = this.getNodeByKey(key)
      this.prepareRuleDetails(node)
      this.displayDetails = true
    },

    // Показывает структуру по ключу правила
    showRuleStructure(key) {
      let node = this.getNodeByKey(key)
      this.prepareRuleTree(node)
      this.displayTree = true
    },

    // Подготавливает данные для показа детальной статистики по правилу
    prepareRuleDetails(node) {
      // console.log('Start preparing data', node)
      // Clear month data
      for (const dataset of this.monthData.datasets) {
        dataset.data = new Array(12).fill(0)
      }
      // Fill month data
      let trueCase = this.monthData.datasets[0].data
      let falseCase = this.monthData.datasets[1].data
      for (const cs of node.data.execs) {
        // console.log(typeof cs.true_answer, cs.true_answer)
        if (cs.true_answer == 1) {
          trueCase[cs.month - 1]++
        } else if (cs.true_answer === 0) {
          falseCase[cs.month - 1]++
        }
      }
      // Clear years data
      this.yearsData.labels = []
      for (const dataset of this.yearsData.datasets) {
        dataset.data = []
      }
      // Fill years data
      trueCase = this.yearsData.datasets[0].data
      falseCase = this.yearsData.datasets[1].data
      const labels = this.yearsData.labels
      for (const cs of node.data.execs) {
        const yr = cs.year.toString()
        if (labels.length == 0 || labels[labels.length - 1] != yr) {
          labels.push(yr)
          trueCase.push(0)
          falseCase.push(0)
        }
        // console.log(typeof cs.true_answer, cs.true_answer)
        if (cs.true_answer == 1) {
          trueCase[labels.length - 1]++
        } else if (cs.true_answer === 0) {
          falseCase[labels.length - 1]++
        }
      }
      // Fill data table details
      this.ruleData = node.data.execs

      // Fill rule structure
      let factors = this.getParents(node.key)
      let fishers = Object.values(node.data.fisher)
      this.ruleStructure = []
      for (let i = 0; i < factors.length; i++) {
        let fullName = this.getFullName(factors[i])
        let fisher = fishers[i]
        this.ruleStructure.push({
          name: fullName,
          fisher: 'Фишер: ' + fisher,
        })
      }
      let details =
        ' Вероятность: ' +
        Number(node.data.probability).toFixed(2) +
        ', объектов: ' +
        node.data.objects
      this.ruleStructure.push({
        name: node.data.name,
        details: details,
      })
    },

    // Подготавливает данные по периодам
    preparePeriods() {
      let monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ]
      let periods = {}

      this.mapRules((node) => {
        if (node.data.execs != null) {
          for (let p of node.data.execs) {
            if (!(p.year in periods)) {
              periods[p.year] = {
                year: p.year,
                true_count: 0,
                true_percent: 0,
                false_count: 0,
                false_percent: 0,
                break_count: 0,
                break_percent: 0,
                false_rules: [],
                true_rules: [],
                months: {},
              }
              for (let i = 0; i < monthNames.length; i++) {
                periods[p.year].months[i + 1] = {
                  month: monthNames[i],
                  true_count: 0,
                  true_percent: 0,
                  false_count: 0,
                  false_percent: 0,
                  break_count: 0,
                  break_percent: 0,
                }
              }
            }

            // Добавление статистики
            let period = periods[p.year]
            if (p.true_answer == 1) {
              period.true_count++
              period.months[p.month].true_count++
            } else if (p.true_answer == 0) {
              period.false_count++
              period.months[p.month].false_count++
            } else {
              period.break_count++
              period.months[p.month].break_count++
            }

            period.true_percent =
              (100 * period.true_count) /
              (period.false_count + period.true_count)
            period.true_percent = Math.round(period.true_percent * 10) / 10

            period.months[p.month].true_percent =
              (100 * period.months[p.month].true_count) /
              (period.months[p.month].false_count +
                period.months[p.month].true_count)
            period.months[p.month].true_percent =
              Math.round(period.months[p.month].true_percent * 10) / 10

            period.false_percent =
              (100 * period.false_count) /
              (period.false_count + period.true_count)
            period.false_percent = Math.round(period.false_percent * 10) / 10

            period.months[p.month].false_percent =
              (100 * period.months[p.month].false_count) /
              (period.months[p.month].false_count +
                period.months[p.month].true_count)
            period.months[p.month].false_percent =
              Math.round(period.months[p.month].false_percent * 10) / 10

            period.break_percent =
              (100 * period.break_count) /
              (period.false_count + period.true_count + period.break_count)
            period.break_percent = Math.round(period.break_percent * 10) / 10

            period.months[p.month].break_percent =
              (100 * period.months[p.month].break_count) /
              (period.months[p.month].false_count +
                period.months[p.month].true_count +
                period.months[p.month].break_count)
            period.months[p.month].break_percent =
              Math.round(period.months[p.month].break_percent * 10) / 10
          }
        }
      }, this.filteredValue)

      this.periodsData = Object.values(periods)

      // Данные для графика
      let dataTruePercent = []
      let dataFalsePercent = []
      let dataBreakPercent = []

      for (let row of this.periodsData) {
        dataTruePercent.push(row.true_percent)
        dataFalsePercent.push(row.false_percent)
        dataBreakPercent.push(row.break_percent)
      }

      this.periodsLineChart = {
        labels: Object.keys(periods),
        datasets: [
          {
            label: 'Подтвердилось',
            fill: false,
            tension: 0.4,
            borderColor: '#66BB6A',
            data: dataTruePercent,
          },
          {
            label: 'Нарушилось',
            fill: true,
            borderColor: '#FFA726',
            tension: 0.4,
            backgroundColor: 'rgba(255,167,38,0.2)',
            data: dataFalsePercent,
          },
          {
            label: 'Не сложилось',
            fill: false,
            borderDash: [5, 5],
            tension: 0.4,
            borderColor: '#42A5F5',
            data: dataBreakPercent,
          },
        ],
      }
    },

    // Подготавливает данные по факторам
    prepareFactors() {
      let factors = {}

      this.mapRules((node) => {
        if (!node.children) {
          // Конец правила
          let ruleFactors = this.getParents(node.key)
          for (let factor of ruleFactors) {
            if (!(factor.data.name in factors)) {
              // Создаем новый фактор в статистике
              factors[factor.data.name] = {
                factor: factor.data.name,
                total: 0,
                lags: {},
              }
            }

            // Добавление статистики
            let f = factors[factor.data.name]
            f.total++

            if (!(factor.data.lag in f.lags)) {
              f.lags[factor.data.lag] = {
                lag: factor.data.lag,
                total: 0,
              }
            }
            f.lags[factor.data.lag].total++

            let lagPlusChange = factor.data.lag + ' : ' + factor.data.change
            if (!(lagPlusChange in f.lags)) {
              f.lags[lagPlusChange] = {
                lag: lagPlusChange,
                total: 0,
              }
            }
            f.lags[lagPlusChange].total++

            for (let p of node.data.execs) {
              if (!(p.year in f)) {
                f[p.year] = 0
              }
              if (!(p.year in f.lags[factor.data.lag])) {
                f.lags[factor.data.lag][p.year] = 0
              }
              if (!(p.year in f.lags[lagPlusChange])) {
                f.lags[lagPlusChange][p.year] = 0
              }
              if (p.true_answer == 1) {
                f[p.year]++
                f.lags[factor.data.lag][p.year]++
                f.lags[lagPlusChange][p.year]++
              }
            }
          }
        }
      }, this.filteredValue)

      this.factorsData = Object.values(factors)

      // Формирование графика
      let colors = ['#42A5F5', '#66BB6A', '#FFA726', 'red', 'purple']
      this.factorsBarChart = {
        labels: [
          ...[...Array(11).keys()].map((i) => (i + 2011).toString()),
          'total',
        ],
        datasets: [],
      }
      // Сортируем факторы по числу вколючения в правила
      let factorsSorted = this.factorsData.sort((a, b) =>
        a.total < b.total ? 1 : -1
      )
      for (let i = 0; i < 5; i++) {
        let dataset = {
          type: 'bar',
          label: factorsSorted[i].factor,
          backgroundColor: colors[i],
          data: [],
        }
        for (let label of this.factorsBarChart.labels) {
          dataset.data.push(factorsSorted[i][label])
        }
        this.factorsBarChart.datasets.push(dataset)
      }
      console.log('factorsbarChat', this.factorsBarChart)
    },

    // Подготовливает данные по правилам в табличной форме
    prepareRulesTable() {
      this.rulesTableData = []
      this.mapRules((node) => {
        if (node.children == null) {
          let factors = this.getParents(node.key)
          for (let factor of factors) {
            let f = {
              key: factor.key,
              name: factor.data.name,
              lag: factor.data.lag,
              change: factor.data.change,
              fisher: node.data.fisher[factor.key],
              influence: node.data.influence[factor.key],
              variable: {
                key: node.key,
                name: node.data.name + ' если:',
                goal: node.data.goal,
                probability: node.data.probability,
                objects: node.data.objects,
              },
            }
            this.rulesTableData.push(f)
          }
        }
      }, this.filteredValue)
    },

    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    },

    expandAll() {
      for (let node of this.nodes) {
        this.expandNode(node)
      }
      this.expandedKeys = { ...this.expandedKeys }
    },
    collapseAll() {
      this.expandedKeys = {}
    },
    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true

        for (let child of node.children) {
          this.expandNode(child)
        }
      }
    },
    // Загружает правила из json файла
    async openFile() {
      // console.log('Open File enter')
      const options = {
        // List of allowed MIME types, defaults to `*/*`.
        mimeTypes: ['application/json'],
        // List of allowed file extensions (with leading '.'), defaults to `''`.
        extensions: ['.json'],
        // Set to `true` for allowing multiple files, defaults to `false`.
        multiple: false,
        // Textual description for file dialog , defaults to `''`.
        description: 'JSON files',
        // Suggested directory in which the file picker opens. A well-known directory or a file handle.
        // startIn: '.',
        // By specifying an ID, the user agent can remember different directories for different IDs.
        id: 'projects',
      }
      try {
        await this.openFileDialog(options)
        this.analysisInfo = this.nodes[0].data.analysisInfo
        this.dataURL = this.nodes[0].data.dataURL
      } catch (e) {
        this.resetTreeData()
        this.reportError('Ошибка при открытии файла', e)
      }
    },

    async openFileDialog(options) {
      const blob = await fileOpen(options)
      // console.log('Blob', await blob.text())
      this.nodes = await this.nodeService.getTreeNodesFromFile(URL.createObjectURL(blob))
    },

    // Сохраняет правила в json файл
    async saveFile() {
      if (this.nodes && this.nodes.length > 0) {
        this.nodes[0].data.analysisInfo = this.analysisInfo
        this.nodes[0].data.dataURL = this.dataURL
      }

      // console.log('nodes[0]', this.nodes[0], this.analysisInfo)
      const jsonString = '{"root":' + JSON.stringify(this.nodes) + '}'
      const blob = new Blob([jsonString], {
        type: 'application/json',
      })
      try {
        const handle = await window.showSaveFilePicker({
          suggestedName: 'untitled.json',
          types: [
            {
              description: 'Json Files',
              accept: {
                'application/json': ['.json'],
              },
            },
          ],
        })
        const writable = await handle.createWritable()
        await writable.write(blob)
        await writable.close()
        return handle
      } catch (err) {
        console.error(err.name, err.message)
      }
    },

    // Экспортирует правила в csv файл
    async exportCsvFile() {
      let csvString = ''
      this.mapRules((node) => {
        if (node.children == null) {
          let factors = this.getParents(node.key)
          for (let factor of factors) {
            csvString += this.getFullName(factor) + '|'
          }
          csvString += node.data.name + '\n'
        }
      }, this.filteredValue)

      const blob = new Blob([csvString], {
        type: 'application/json',
      })
      try {
        const handle = await window.showSaveFilePicker({
          suggestedName: 'untitled.csv',
          types: [
            {
              description: 'CSV Files',
              accept: {
                'text/csv': ['.csv'],
              },
            },
          ],
        })
        const writable = await handle.createWritable()
        await writable.write(blob)
        await writable.close()
        return handle
      } catch (err) {
        console.error(err.name, err.message)
      }
    },

    // Сохраняет правила в json файл
    async saveFile1() {
      // Save a file.
      if (this.nodes) {
        // Options are optional. You can pass an array of options, too.
        const options = {
          // Suggested file name to use, defaults to `''`.
          fileName: 'Untitled.json',
          // Suggested file extensions (with leading '.'), defaults to `''`.
          extensions: ['.json'],
          // Suggested directory in which the file picker opens. A well-known directory or a file handle.
          // startIn: 'downloads',
          // By specifying an ID, the user agent can remember different directories for different IDs.
          // id: 'projects',
        }

        // Optional file handle to save back to an existing file.
        // This will only work with the File System Access API.
        // Get a `FileHandle` from the `handle` property of the `Blob`
        // you receive from `fileOpen()` (this is non-standard).
        // const existingHandle = previouslyOpenedBlob.handle

        // Optional flag to determine whether to throw (rather than open a new file
        // save dialog) when `existingHandle` is no longer good, for example, because
        // the underlying file was deleted. Defaults to `false`.
        const throwIfExistingHandleNotGood = false
        const jsonString = '{"root":' + JSON.stringify(this.nodes) + '}'
        // console.log('JSON NODES', jsonString)
        const blob = new Blob([jsonString], {
          type: 'application/json',
        })
        // const blob = new Blob([jsonString])
        console.log(options)
        await fileSave(blob, null, null, throwIfExistingHandleNotGood)
      }
    },

    resetTreeData() {
      this.nodes = null
      this.analysisInfo = null
      this.dataURL = null
    },

    reportError(summary, detail, e) {
      const err = e ? e : `summary: ${summary}\ndetail: ${detail}`

      this.$toast.add({severity: 'error', summary: summary, detail: detail, life: 5000,})
      console.error('something went wrong', err)
    },
  },
}
</script>

<style scoped lang="scss">
// .p-filter-column {
//   .p-multiselect,
//   .p-dropdown,
//   .p-inputtext {
//     width: 100%;
//   }
// }
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #bb33d3;
//   margin-top: 60px;
// }

::v-deep(.p-organizationchart) {
  .p-person {
    padding: 0;
    border: 0 none;
  }

  .node-header,
  .node-content {
    padding: 0.5em 0.7rem;
  }

  .node-header {
    background-color: #495ebb;
    color: #ffffff;
  }

  .node-content {
    text-align: center;
    border: 1px solid #495ebb;
  }

  .node-content img {
    border-radius: 50%;
  }

  .department-cfo {
    // background-color: #7247bc;
    // color: #ffffff;
    border: 1px solid #a534b6;
  }

  .department-coo {
    background-color: #a534b6;
    color: #ffffff;
  }

  .department-cto {
    background-color: #e9286f;
    color: #ffffff;
  }

  // Стили для отображения правил
  .rules {
    padding: 0;
    border: 0 none;
    // background-color: #7247bc;
    // color: #ffffff;
    // border: 1px solid #e9286f;
  }

  // .rule-header,
  .rule-content {
    padding: 0.5em 0.7rem;
  }

  .rule-header {
    background-color: var(--pink-200);
    color: var(--primary-color-text);
  }

  .rule-header-alt {
    background-color: var(--purple-200);
    color: var(--primary-color-text);
  }

  // .rule-header {
  //   background-color: #e9286f;
  //   color: #ffffff;
  // }

  .rule-content {
    text-align: center;
    border: 1px solid var(--text-color);
  }
}

// .p-submenu-list,
// .p-menuitem,
// .p-menuitem-text,
// .p-menubar {
//   display: inline;
// }

// .node-content {
//   text-align: center;
//   border: 1px solid #495ebb;
// }
.department-cto {
  background-color: #e9286f;
  color: #ffa726;
}
</style>
