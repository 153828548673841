<template>
  <Timeline :value="ruleStructure" align="alternate">
    <template #opposite="slotProps">
      <small class="p-text-secondary" v-if="slotProps.item.fisher">{{
        slotProps.item.fisher
      }}</small>
      <small class="p-text-secondary" v-else>{{
        slotProps.item.details
      }}</small>
    </template>
    <template #content="slotProps">
      {{ slotProps.item.name }}
    </template>
  </Timeline>

  <div class="card" style="width: 90%;">
    <h3>По месяцам</h3>
    <Chart type="bar" :data="monthData" :options="horizontalOptions" />
  </div>
  <div class="card" style="width: 90%;">
    <h3>По годам</h3>
    <Chart type="bar" :data="yearsData" :options="horizontalOptions" />
  </div>
  <div class="card">
    <h3>Детально</h3>
    <DataTable :value="ruleData" class="p-datatable-sm">
      <Column field="year" header="Год"></Column>
      <Column field="month" header="Месяц">
        <template #body="slotProps">
          {{ monthData.labels[slotProps.data.month - 1] }}
        </template></Column
      >
      <Column field="true_answer" header="Результат">
        <template #body="slotProps">
          {{
            ['Не сложилось', 'Нарушилось', 'Подтвердилось'][
              slotProps.data.true_answer + 1
            ]
          }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import Timeline from 'primevue/timeline'
import Chart from 'primevue/chart'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
export default {
  props: {
    ruleStructure: {
      type: Object,
      default: null,
      required: true,
    },
    monthData: {
      type: Object,
      default: null,
      required: true,
    },
    yearsData: {
      type: Object,
      default: null,
      required: true,
    },
    ruleData: {
      type: Object,
      default: null,
      required: true,
    },
  },
  components: {
    Timeline,
    Chart,
    DataTable,
    Column,
  },
  data() {
    return {
      horizontalOptions: {
        indexAxis: 'y',
        plugins: {
          legend: {
            labels: {
              color: '#495057',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.department-cto {
  background-color: #e9286f;
  color: #46911a;
}
</style>
